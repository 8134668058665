import React from "react"
import PropTypes from "prop-types"

import Image from "./Image"
import Heading from "./Heading"
import Link from "./LinkTM"
import ValueProp from "./ValueProp"

import styles from "./onebyone.module.scss"
import linkStyles from "./link.module.scss"
import settings from "./settings.module.scss"

import { toTitleCase } from "../helpers"


const OneByOne = ({ data }) => {
  const {
    components,
    backgroundColor,
  } = data

  return (
    <section className={styles.oneByOne}>
      {components.map((component, index) =>
        <ComponentContainer
          key={index}
          component={component}
          backgroundColor={backgroundColor}
          componentName={data.componentName}
        />
      )}
    </section>
  )
}

const ComponentContainer = ({ component, backgroundColor, componentName }) => {

  const textAlign = toTitleCase(component.textAlignment || "left")
  const isValueProp = component.internal && component.internal.type === "ContentfulValueProp"

  return(
    <div
      className={`${styles.container} ${settings[`textAlign${textAlign}`]} ${(isValueProp) ? styles.valuePropContainer : ""}`}
      style={{backgroundColor: backgroundColor || ""}}
    >
      {component.desktopImage &&
        <>
          {component.desktopImage &&
            <Image
              image={component}
              altText={component.altText}
              options={{ sizing: "one-by-one" }}
              className={styles.image}
            />
          }
          {component.subtitle &&
            <Heading
              tag="h4"
              level="4"
              className={styles.imageHeading}
            >
              {component.subtitle}
            </Heading>
          }
          {component.url &&
            <Link
              url={`${component.url}${(component.url.includes("?")) ? "&" : "?"}origin=${componentName.toLowerCase().replace(/\s/g, "-")}`}
              className={linkStyles.linkFull}
            />
          }
        </>
      }
      {component.desktopVideo &&
      // eslint-disable-next-line jsx-a11y/media-has-caption
      <>
        {component.mobileVideo &&
        <video autoPlay loop muted playsInline className={styles.videoMobile}>
          <source
            src={component.mobileVideo.file.url}
            type="video/mp4" />
        </video>
        }
        <video autoPlay loop muted playsInline className={styles.video}>
          <source
            src={component.desktopVideo.file.url}
            type="video/mp4" />
        </video>
      </>
      }
      {isValueProp &&
        <ValueProp
          data={component}
          className={styles.valueProp}
          customStyles={styles}
        />
      }
    </div>
  )
}

OneByOne.propTypes = {
  data: PropTypes.object.isRequired,
}

export default OneByOne
