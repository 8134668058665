import React from "react"
import { graphql } from "gatsby"

import EmailCapture from "./EmailCapture"

import styles from "./emailCapture.module.scss"

const Email = ({ data, location }) => {

  return (
    <section className={styles.module}>
      <EmailCapture
        heading={data.heading}
        text={data.text}
        textAlignment={data.textAlignment}
        submitText={data.cta.text}
        disclaimerText={data.disclaimerRichText}
        confirmationHeading={data.confirmationHeading}
        confirmationText={data.confirmationText}
        confirmationCta={data.confirmationCta}
        listId={data.listId}
        source={data.source}
        formName={data.formName}
        captureSource={data.captureSource}
        captureTrafficSource={data.captureTrafficSource}
        captureLocation={data.captureLocation}
        offerDiscountCode={data.offerDiscountCode}
        location={location}
      />
    </section>
  )
}

export default Email

export const query = graphql`
  fragment EmailFragment on ContentfulEmailCapture {
    componentName
    slug
    listId
    heading {
      heading
    }
    text {
      json
    }
    textAlignment
    cta {
      text
    }
    disclaimerRichText {
      json
    }
    confirmationHeading {
      heading
    }
    confirmationText
    confirmationCta {
      url
      text
    }
    formName
    source
    captureSource
    captureLocation
    captureTrafficSource
    offerDiscountCode
    internal {
      type
    }
    id
  }
`
