import React from "react"
import PropTypes from "prop-types"

import Hero from "./Hero"
import ValueProp from "./ValueProp"
import TextBlock from "./TextBlock"
import WideImage from "./WideImage"
import TwoImages from "./TwoImages"
import Email from "./Email"
import Iframe from "./Iframe"

import ValueProps from "./ValueProps"
import CategoryGrid from "./CategoryGrid"
import UGC from "./UGC"
import OneByOne from "./OneByOne"
import Promos from "./Promos"
import ProductSwiper from "./ProductSwiper"
import HomeSwiper from "./HomeSwiper"
import HeroSwiper from "./HeroSwiper"

const Module = ({ data, location, navigate }) => {

  return (
    <>
      {data.internal && 
        <>
          {data.internal.type === "ContentfulFullWidthImage" &&
            <Hero data={data} location={location} navigate={navigate} />
          }
          {data.internal.type === "ContentfulValueProp" &&
            <ValueProp data={data} />
          }
          {data.internal.type === "ContentfulTextBlock" &&
            <TextBlock data={data} />
          }
          {data.internal.type === "ContentfulWideImage" &&
            <WideImage data={data} />
          }
          {data.internal.type === "ContentfulTwoImages" &&
            <TwoImages data={data} />
          }
          {data.internal.type === "ContentfulEmailCapture" &&
            <Email data={data} location={location} />
          }
          {data.internal.type === "ContentfulIframe" &&
            <Iframe data={data} />
          }
          {data.internal.type === "ContentfulMultipleComponents" && data.layout &&
            <>
              {data.layout === "Category Grid" &&
                <CategoryGrid data={data} />
              }
              {data.layout === "Value Props" &&
                <ValueProps data={data} />
              }
              {data.layout === "UGC" &&
                <UGC data={data} />
              }
              {data.layout === "1x1" &&
                <OneByOne data={data} />
              }
              {data.layout === "Promos" &&
                <Promos data={data} location={location} />
              }
              {data.layout === "Product Grid" &&
                <ProductSwiper data={data} />
              }
              {data.layout === "Homepage" &&
                <HomeSwiper data={data} location={location} navigate={navigate} />
              }
              {data.layout === "Hero" &&
                <HeroSwiper data={data} />
              }
            </>
          }
        </>
      }
    </>
  )
}

Module.propTypes = {
  data: PropTypes.object.isRequired,
}

export default Module
