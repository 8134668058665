import React from "react"
import { graphql, Link } from "gatsby"
import PropTypes from "prop-types"
import Swiper from 'react-id-swiper';

import Heading from "./Heading"
import Image from "./Image"
import { getDefaultParams } from "./Swiper"

import styles from "./ugc.module.scss"


const UGC = ({ data }) => {
  const {
    heading,
    components,
  } = data

  const defaultParams = getDefaultParams(data, styles)

  const params = {
    ...defaultParams,
    ...{
      spaceBetween: 8,
      slidesPerView: 1.25,
      loop: true,
      centeredSlides: true,
      preloadImage: true,
      breakpoints: {
        // when window width is >= 768px
        768: {
          slidesPerView: 5,
          loop: false,
          centeredSlides: false,
        },
      },
    }
  }

  const headingLevel = (heading.textSize || "2").replace("heading-","")
  const headingStyle = styles[`heading${headingLevel}`]

  return (
    <section className={styles.ugc}>
      {heading &&
        <Heading
          tag="h2"
          level={headingLevel}
          className={headingStyle}
        >
          {heading.heading}
        </Heading>
      }
      <Swiper {...params}>
        {components.map(image =>
          <li className={styles.swiperSlide} key={image.id}>
            {image && image.desktopImage && image.url &&
              <Link
                to={image.url}
              >
                <Image
                  image={image}
                  options={{ sizing: "category-grid" }}
                />
              </Link>
            }
            {image && image.desktopImage && !image.url &&
              <Image
                image={image}
                options={{ sizing: "category-grid" }}
              />
            }
          </li>
        )}
      </Swiper>
    </section>
  )
}

UGC.propTypes = {
  data: PropTypes.object.isRequired,
}

export default UGC

export const query = graphql`
  fragment UGCFragment on ContentfulImage {
    desktopImage {
      fluid(maxWidth: 850, quality: 90) {
        ...GatsbyContentfulFluid_withWebp_noBase64
      }
    }
    altText
    subtitle
    textAlignment
    url
    id
  }
`
