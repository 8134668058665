import React  from "react"
import { graphql, Link } from "gatsby"
import PropTypes from "prop-types"

import Heading from "./Heading"
import Image from "./Image"
import Button from "./Button"

import styles from "./hero.module.scss"
import linkStyles from "./link.module.scss"
import settings from "./settings.module.scss"

import { toTitleCase } from "../helpers"


const Hero = ({ data, customStyles, location, navigate }) => {

  const {
    layout,
    image,
    video,
    button,
    imageOverlay,
    heading,
    headingOrientation,
    headingOrientationMobile,
    // text,
    url,
    imageAlignment,
    contentAlignment,
    textColor,
    textColorMobile,
  } = data

  const layoutType = toTitleCase(layout || "original")
  // const imageAlign = (imageAlignment)
  //   ? toTitleCase(imageAlignment.horizontalVertical).replace(/\s/g,"")
  //   : "CenterMiddle"
  const imageAlign = (imageAlignment)
    ? imageAlignment.horizontalVertical.replace("middle","center")
    : "center center"
  const contentAlign = (contentAlignment)
    ? toTitleCase(contentAlignment.horizontalVertical).replace(/\s/g,"")
    : "CenterMiddle"
  const colorScheme = toTitleCase(textColor || "light")
  const colorSchemeMobile = toTitleCase(textColorMobile || textColor || "light")
  const headingAlign = (headingOrientation)
    ? toTitleCase(headingOrientation.horizontalVertical).replace(/\s/g,"")
    : "CenterMiddle"
  const headingAlignMobile = (headingOrientationMobile)
    ? toTitleCase(headingOrientationMobile.horizontalVertical).replace(/\s/g,"")
    : headingAlign

  const sectionStyle = [
    styles.hero,
    styles[`layout${layoutType}`],
    settings[`contentAlign${contentAlign}`],
    settings[`colorScheme${colorScheme}`],
    settings[`colorSchemeMobile${colorSchemeMobile}`],
    (heading) ? styles[`headingAlign${headingAlign}`] : "",
    (heading && headingOrientationMobile) ? styles[`headingAlignMobile${headingAlignMobile}`] : "",
    (customStyles) ? customStyles.hero : "",
  ].join(" ")
  const imageOverlaystyle = [
    styles.imageOverlay,
    settings.imageOverlay,
    (customStyles) ? customStyles.imageOverlay : "",
  ].join(" ")
  const headingStyle = [
    styles.heading1,
    settings.heading1,
    (customStyles) ? customStyles.heading1 : "",
  ].join(" ")
  // const subHeadingStyle = [
  //   styles.subHeading,
  //   settings.subHeading,
  //   (customStyles) ? customStyles.subHeading : "",
  // ].join(" ")
  const btnStyle = [
    styles.btn,
    settings.btn,
    (customStyles) ? customStyles.btn : "",
  ].join(" ")

  return (
    <section className={sectionStyle}>
      {image && image.mobileImage &&
        <Image
          image={image}
          options={{ sizing: "art-directed", alignment: imageAlign }}
          className={styles.imageHero}
          customStyles={{ position: "absolute" }}
        />
      }
      {video && video.mobileVideo && typeof window !== "undefined" && window.outerWidth < 768 &&
        // eslint-disable-next-line jsx-a11y/media-has-caption
        <video autoPlay loop muted playsInline className={styles.video}>
          <source
            src={video.mobileVideo.file.url}
            type="video/mp4" />
        </video>
      }
      {video && typeof window !== "undefined" && window.outerWidth >= 768 &&
        // eslint-disable-next-line jsx-a11y/media-has-caption
        <video autoPlay loop muted playsInline className={styles.video}>
          <source
            src={video.desktopVideo.file.url}
            type="video/mp4" />
        </video>
      }
      {button && button.style === "Button" &&
        <Button
          type={button.type}
          url={button.url}
          color={button.color}
          outline={button.outline}
          className={btnStyle}
        >
          {button.text}
        </Button>
      }
      {imageOverlay &&
        <Image
          image={imageOverlay}
          altText={imageOverlay.altText}
          options={{ sizing: "art-directed" }}
          className={imageOverlaystyle}
        />
      }
      {heading &&
        <Heading
          tag="h1"
          level="1"
          className={headingStyle}
        >
          {heading.heading}
        </Heading>
      }
      {/*{text &&*/}
      {/*  <p*/}
      {/*    className={subHeadingStyle}*/}
      {/*  >*/}
      {/*    {text.text}*/}
      {/*  </p>*/}
      {/*}*/}
      {url &&
        <Link
          to={`${url}${(url.includes("?")) ? "&" : "?"}origin=${data.componentName.toLowerCase().replace(/\s/g, "-")}`}
          className={linkStyles.linkFull}
        />
      }
    </section>
  )
}

Hero.propTypes = {
  data: PropTypes.object.isRequired,
  customStyles: PropTypes.object,
}

export default Hero

export const query = graphql`
  fragment HeroFragment on ContentfulFullWidthImage {
    componentName
    slug
    layout
    image {
      desktopImage {
        fluid(maxWidth: 2560, quality: 90) {
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
      }
      mobileImage {
        fluid(maxWidth: 768, quality: 90) {
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
      }
      altText
    }
    imageAlignment {
      horizontalVertical
    }
    video {
      ...VideoFragment
    }
    imageOverlay {
      desktopImage {
        file {
          url
          contentType
        }
      }
      mobileImage {
        file {
          url
          contentType
        }
      }
      altText
    }
    heading {
      ...HeadingFragment
    }
    headingOrientation {
        horizontalVertical
    }
    headingOrientationMobile {
        horizontalVertical
    }
    text {
      text
    }
    button {
      ...ButtonFragment
    }
    textColor
    textColorMobile
    contentAlignment {
      horizontalVertical
    }
    url
    internal {
      type
    }
    id
  }
`
