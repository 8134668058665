import React from "react"
import { graphql } from "gatsby"

import styles from "./iframe.module.scss"


const Iframe = ({ data }) => {

  return (
    <section className={styles.iframe}>
      <iframe
        title={data.slug}
        src={data.url}
        height={data.height}
        width="100%"
      />
    </section>
  )
}

export default Iframe

export const query = graphql`
    fragment IframeFragment on ContentfulIframe {
        componentName
        slug
        url
        height
        internal {
            type
        }
        id
    }
`
