import React from "react"
import PropTypes from "prop-types"
import Swiper from 'react-id-swiper';

import ValueProp from "./ValueProp"
import { getDefaultParams } from "./Swiper"

import styles from "./valueprop.module.scss"


const ValueProps = ({ data }) => {
  const {
    components,
  } = data

  const defaultParams = getDefaultParams(data, styles)

  const params = {
    ...defaultParams,
    ...{
      spaceBetween: 0,
      slidesPerView: 1,
      loop: true,
    }
  }

  return (
    <section className={styles.valueProps}>
      <Swiper {...params}>
        {components.map(valueProp =>
          <li className={styles.swiperSlide} key={valueProp.id}>
            <ValueProp
              data={valueProp}
              isInCarousel={true}
            />
          </li>
        )}
      </Swiper>
    </section>
  )
}

ValueProps.propTypes = {
  data: PropTypes.object.isRequired,
}

export default ValueProps
