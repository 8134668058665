import React from "react"
import { Link } from "gatsby"
import Swiper from "react-id-swiper"

// import Heading from "./Heading"
import Image from "./Image"

import styles from "./homeswiper.module.scss"
import "./homeswiper.scss"

const HomeSwiper = ({ data, location, navigate }) => {

  const {
    // heading,
    components,
  } = data

  const swiperParams = {
    containerClass: `${styles.swiperContainer} home-swiper-container`,
    WrapperEl: "ol",
    // wrapperClass: `${styles.swiperWrapper} swiper-wrapper`,
    threshold: 10,
    // watchOverflow: true,
    // preloadImage: true,
    // updateOnImagesReady: true,
    spaceBetween: 8,
    slidesPerView: 1.25,
    loop: true,
    speed: 10000,
    freeMode: true,
    autoplay: {
      delay: 0,
      disableOnInteraction: false,
      reverseDirection: true,
    },
    breakpoints: {
      // when window width is >= 768px
      768: {
        spaceBetween: 0,
        slidesPerView: (typeof window !== "undefined")
          ? window.outerWidth / window.outerHeight + 1.6
          : 2.3,
      },
    },
    initialSlide: 8,
  }

  return (
    <section className={styles.section}>
      <div className={styles.sectionWrapper}>
        <Swiper {...swiperParams}>
          {components.map((product, i) =>
            <li
              key={i}
              className={styles.swiperSlide}
            >
              <Link
                to={`${product.url}?color=${encodeURIComponent(product.color)}&origin=hp-carousel`}
                className={styles.imageLink}
              >
                <Image
                  image={product.subcatSide}
                  altText={product.name}
                  className={styles.productImage}
                  fadeIn={false}
                />
                <h4 className={styles.productTitle}>{product.name}</h4>
              </Link>
            </li>
          )}
        </Swiper>
        {/*<Heading*/}
        {/*  tag="h2"*/}
        {/*  level="1"*/}
        {/*  className={styles.heading}*/}
        {/*>*/}
        {/*  {heading.heading}*/}
        {/*</Heading>*/}
      </div>
    </section>
  )
}

export default HomeSwiper
