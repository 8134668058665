import React from "react"
import { graphql, Link } from "gatsby"
import PropTypes from "prop-types"

import Heading from "./Heading"
import Button from "./Button"
import RichText from "./RichText"

import styles from "./valueprop.module.scss"
import linkStyles from "./link.module.scss"
import settings from "./settings.module.scss"

import { toTitleCase } from "../helpers"


const ValueProp = ({ data, className, customStyles, isInCarousel }) => {
  const {
    heading,
    description,
    button,
    textColor,
    textAlignment,
    backgroundColor,
    backgroundFullWidth,
  } = data
  
  const moduleWidth = backgroundFullWidth ? "FullWidth" : ""
  const colorScheme = toTitleCase(textColor || "dark")
  const textAlign = toTitleCase(textAlignment || "center")
  const headingLevel = (heading) ? (heading.textSize || "2").replace("heading-","") : "2"
  const headingAlign = (heading) ? toTitleCase(heading.textAlignment || "center") : "Center"
  const headingClassName = `heading${headingLevel}`

  const sectionStyle = `${styles[`valueProp${moduleWidth}`]} ${styles[`textAlign${textAlign}`]} ${settings[`textAlign${textAlign}`]} ${settings[`colorScheme${colorScheme}`]} ${className || ""}`
  const headingStyle = (customStyles) ?
    `${styles[headingClassName]} ${settings[headingClassName]} ${customStyles[headingClassName]}` :
    `${styles[headingClassName]} ${settings[headingClassName]}`
  const textStyle = (customStyles) ?
    `${styles.text} ${customStyles.text}` :
    styles.text

  let linkStyle
  let btnStyle
  if (button) {
    button.style = (isInCarousel) ? "Link" : button.style
    button.color = (isInCarousel) ? "Accent" : button.color
    linkStyle = (customStyles) ?
      `${linkStyles[`link${button.color}`]} ${styles.link} ${customStyles.link}` :
      `${linkStyles[`link${button.color}`]} ${styles.link}`
    btnStyle = (customStyles) ?
      `${styles.btn} ${settings.btn} ${customStyles.btn}` :
      `${styles.btn} ${settings.btn}`
  }

  return (
    <section className={sectionStyle} style={{backgroundColor: backgroundColor}}>
      {heading &&
        <Heading
          tag="h2"
          level={headingLevel}
          alignment={headingAlign}
          className={headingStyle}
        >
          {heading.heading}
        </Heading>
      }
      {description &&
        <div
          className={`${textStyle} ${settings[`textAlign${textAlign}`]}`}
        >
          <RichText
            json={description.json}
          />
        </div>
      }
      {button && button.style === "Button" ?
        <Button
          type={button.type}
          url={button.url}
          color={button.color}
          outline={button.outline}
          className={btnStyle}
        >
          {button.text}
        </Button>
      : button && button.style === "Link" && button.url.includes("http") ?
        <a
          href={button.url}
          target="_blank"
          rel="noopener noreferrer"
          className={linkStyle}
        >
          {button.text}
        </a>
      : button && button.style === "Link" &&
        <Link
          to={button.url}
          className={linkStyle}
        >
          {button.text}
        </Link>
      }
    </section>
  )
}

ValueProp.propTypes = {
  data: PropTypes.object.isRequired,
  className: PropTypes.string,
  customStyles: PropTypes.object,
  isInCarousel: PropTypes.bool,
}

export default ValueProp

export const query = graphql`
  fragment ValuePropFragment on ContentfulValueProp {
    componentName
    slug
    heading {
      ...HeadingFragment
    }
    description {
      json
    }
    button {
      ...ButtonFragment
    }
    textColor
    textAlignment
    backgroundColor
    backgroundFullWidth
    internal {
      type
    }
    id
  }
`
