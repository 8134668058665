import React  from "react"
import { graphql, Link } from "gatsby"
import Swiper from "react-id-swiper"

import Image from "./Image"
import Heading from "./Heading"

import styles from "./heroswiper.module.scss"
import linkStyles from "./link.module.scss"


const HeroSwiper = ({ data }) => {

  const {
    components,
    heading,
    url,
  } = data

  const swiperParams = {
    containerClass: `${styles.swiperContainer} hero-swiper-container`,
    WrapperEl: "ol",
    threshold: 10,
    spaceBetween: 0,
    slidesPerView: 1,
    loop: true,
    autoplay: {
      delay: 5000,
      disableOnInteraction: false,
    },
  }

  return (
    <section className={styles.section}>
      <Swiper {...swiperParams}>
        {components.map((image, i) =>
          <li
            key={i}
            className={styles.swiperSlide}
          >
            <Image
              image={image}
              options={{ sizing: "art-directed", alignment: "CenterBottom" }}
              className={styles.imageHero}
              customStyles={{ position: "absolute" }}
            />
          </li>
        )}
      </Swiper>
      <Heading
        tag="h1"
        level="1"
        className={styles.heading}
      >
        {heading.heading}
      </Heading>
      {url &&
        <Link
          to={`${url}${(url.includes("?")) ? "&" : "?"}origin=${data.componentName.toLowerCase().replace(/\s/g, "-")}`}
          className={linkStyles.linkFull}
        />
      }
    </section>
  )

}

export default HeroSwiper

export const query = graphql`
    fragment HeroSwiperFragment on ContentfulImage {
        desktopImage {
            fluid(maxWidth: 2560, quality: 90) {
                ...GatsbyContentfulFluid_withWebp_noBase64
            }
        }
        mobileImage {
            fluid(maxWidth: 768, quality: 90) {
                ...GatsbyContentfulFluid_withWebp_noBase64
            }
        }
        altText
    }
`
