import React from "react"
import PropTypes from "prop-types"
import Swiper from 'react-id-swiper';

import Heading from "./Heading"
import Hero from "./Hero"
import { getDefaultParams } from "./Swiper"

import styles from "./promos.module.scss"


const Promos = ({ data, location }) => {
  const {
    heading,
    components,
  } = data

  const defaultParams = getDefaultParams(data, styles)

  const params = {
    ...defaultParams,
    ...{
      spaceBetween: 0,
      slidesPerView: 1,
      loop: components.length > 1,
      speed: 700,
      autoplay: (components.length > 1) ?
        {
          delay: 5000,
        } : false,
      effect: 'fade',
    }
  }

  const headingLevel = heading ? (heading.textSize || "2").replace("heading-","") : "2"

  return (
    <section className={styles.promos} id={data.componentName.toLowerCase().replace(/\s/g,"-")}>
      {heading &&
        <Heading
          tag="h2"
          level={headingLevel}
          className={styles.heading}
        >
          {heading.heading}
        </Heading>
      }
      <Swiper {...params}>
        {components.map(promo =>
          <li className={styles.swiperSlide} key={promo.id}>
            <Hero
              data={promo}
              location={location}
              customStyles={styles}
            />
          </li>
        )}
      </Swiper>
    </section>
  )
}

Promos.propTypes = {
  data: PropTypes.object.isRequired,
}

export default Promos
