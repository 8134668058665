import React  from "react"
import { graphql } from "gatsby"
import { Helmet } from "react-helmet/es/Helmet"

import SEO from "../components/SEO"
import Module from "../components/Module"

const Page = ({ data, location, navigate }) => {
  
  return (
    <>
      <SEO
        title={data.contentfulPage.pageTitle}
        description={`Redefining luxury with the finest boots, pumps, sandals, and flats. Time-honored luxury shoes at direct-to-women prices.`}
        url={location.pathname}
      />

      {data.contentfulPage.components.map((module, index) =>
        <Module
          data={module}
          key={index}
          location={location}
          navigate={navigate}
        />
      )}

      <Helmet>
        {location.pathname === "/" &&
          <script type="text/javascript">{`
            window.criteo_q = window.criteo_q || [];
            window.criteo_q.push(
              { event: "viewHome", ecpplugin: "shopify"}
            );
          `}</script>
        }
        {/*Calendly*/}
        <script>{`
          (function(d) {
            var e = d.createElement('script');
            e.src = d.location.protocol + '//assets.calendly.com/assets/external/widget.js';
            e.async = true;
            d.getElementsByTagName("head")[0].appendChild(e);
            var f = d.createElement('link');
            f.href = d.location.protocol + '//assets.calendly.com/assets/external/widget.css';
            f.rel = 'stylesheet';
            d.getElementsByTagName("head")[0].appendChild(f);
          }(document));
        `}</script>
      </Helmet>
    </>
  )
}

export default Page

export const pageQuery = graphql`
  query($pageUrl: String!) {
    contentfulPage(pageUrl: {eq: $pageUrl}) {
      pageTitle
      pageUrl
      components {
        ... on ContentfulFullWidthImage {
          ...HeroFragment
        }
        ... on ContentfulValueProp {
          ...ValuePropFragment
        }
        ... on ContentfulTextBlock {
          ...TextBlockFragment
        }
        ... on ContentfulWideImage {
          ...WideImageFragment
        }
        ... on ContentfulTwoImages {
          ...TwoImagesFragment
        }
        ... on ContentfulEmailCapture {
          ...EmailFragment
        }
        ... on ContentfulIframe {
          ...IframeFragment
        }
        ... on ContentfulMultipleComponents {
          componentName
          slug
          heading {
            ...HeadingFragment
          }
          components {
            ... on ContentfulImage {
              ...HeroSwiperFragment
            }
            ... on ContentfulValueProp {
              ...ValuePropFragment
            }
            ... on ContentfulFullWidthImage {
              ...HeroFragment
            }
            ... on ContentfulProductTile {
              ...ProductFragment
            }
            ... on ContentfulVideo {
              ...VideoFragment
            }
          }
          initCarousel
          navigation
          pagination
          layout
          hidePricing
          url
          internal {
            type
          }
          id
        }
      }
    }
  }
`
