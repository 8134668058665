import React from "react"
import { graphql } from "gatsby"
import PropTypes from "prop-types"

import Heading from "./Heading"
import RichText from "./RichText"

import styles from "./textblock.module.scss"
import settings from "./settings.module.scss"

import { toTitleCase } from "../helpers"


const TextBlock = ({ data }) => {
  const {
    heading,
    headingPosition,
    description,
    textAlignment,
  } = data

  const headingAlign = (heading) ? toTitleCase(heading.textAlignment || "left") : "Left"

  const headingPositionFormatted = toTitleCase(headingPosition || "left")

  const headingLevel = (heading) ? (heading.textSize || "2").replace("heading-","") : "2"
  const headingStyle = `${styles.heading} ${settings[`textAlign${headingAlign}`]}`

  const textAlign = toTitleCase(textAlignment || "left")

  return (
    <section className={`${styles.textBlock} ${styles[`heading${headingPositionFormatted}`]}`}>
      {heading &&
        <Heading
          tag="h2"
          level={headingLevel}
          className={headingStyle}
        >
          {heading.heading}
        </Heading>
      }
      {description &&
        <div
          className={`${styles.contentContainer} ${settings[`textAlign${textAlign}`]}`}
        >
          <RichText
            json={description.json}
          />
        </div>
      }
    </section>
  )
}

TextBlock.propTypes = {
  data: PropTypes.object.isRequired,
}

export default TextBlock

export const query = graphql`
  fragment TextBlockFragment on ContentfulTextBlock {
    componentName
    slug
    heading {
      ...HeadingFragment
    }
    headingPosition
    description {
      json
    }
    textAlignment
    internal {
      type
    }
    id
  }
`
