import React from "react"
import { graphql } from "gatsby"
import PropTypes from "prop-types"

import Image from "./Image"
import Link from "./LinkTM"

import styles from "./wideimage.module.scss"
import linkStyles from "./link.module.scss"
import settings from "./settings.module.scss"

import { toTitleCase } from "../helpers"


const WideImage = ({ data }) => {
  const {
    image,
    video,
    subtitle,
    link,
    fullWidth,
  } = data

  const textAlign = (image) ? toTitleCase(image.textAlignment || "left") : "Left"

  return (
    <section className={`${styles[`wideImage${(fullWidth) ? "FullWidth" : ""}`]} ${settings[`textAlign${textAlign}`]}`}>
      {image &&
        <Image
          image={image}
          altText={image.altText}
          options={(image.mobileImage) ? { sizing: "art-directed" } : {}}
          className={(image.mobileImage) ? styles.imageWithMobile : styles.image}
        />
      }
      {image && image.subtitle &&
        <h4 className={styles.heading}>
          {image.subtitle}
        </h4>
      }
      {video &&
        // eslint-disable-next-line jsx-a11y/media-has-caption
        <>
          {video.mobileVideo &&
            <video autoPlay loop muted playsInline className={styles.videoMobile}>
              <source
                src={video.mobileVideo.file.url}
                type="video/mp4" />
            </video>
          }
          <video autoPlay loop muted playsInline className={styles.video}>
            <source
              src={video.desktopVideo.file.url}
              type="video/mp4" />
          </video>
        </>
      }
      {subtitle &&
        <p className={styles.subtitle}>
          {subtitle.text}
        </p>
      }
      {link &&
        <Link
          url={link.url}
          text={link.text}
          className={linkStyles.linkAccent}
        />
      }
      {image && image.url &&
        <Link
          url={`${image.url}${(image.url.includes("?")) ? "&" : "?"}origin=${data.componentName.toLowerCase().replace(/\s/g, "-")}`}
          className={linkStyles.linkFull}
        />
      }
    </section>
  )
}

WideImage.propTypes = {
  data: PropTypes.object.isRequired,
}

export default WideImage

export const query = graphql`
  fragment WideImageFragment on ContentfulWideImage {
    componentName
    slug
    image {
      desktopImage {
        fluid(maxWidth: 2560, quality: 90) {
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
      }
      mobileImage {
        fluid(maxWidth: 768, quality: 90) {
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
      }
      altText
      url
      subtitle
      textAlignment
    }
    video {
        ...VideoFragment
    }
    subtitle
    link {
      ...ButtonFragment
    }
    fullWidth
    internal {
      type
    }
    id
  }
`
