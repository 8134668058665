import React from "react"
import { Link } from "gatsby"
import PropTypes from "prop-types"

import Image from "./Image"

import styles from "./categorygrid.module.scss"


const CategoryGrid = ({ data }) => {
  const {
    components,
  } = data

  return (
    <section className={styles.categoryGrid}>
      <ul className={styles.gridContainer}>
        {components.map(image =>
          <li className={styles.imageContainer} key={image.id}>
            {image && image.desktopImage &&
              <Image
                image={image}
                options={{ sizing: "category-grid" }}
              />
            }
            {image && image.url &&
              <Link
                to={image.url}
                className={styles.link}
              >
                {image.altText}
              </Link>
            }
          </li>
        )}
      </ul>
    </section>
  )
}

CategoryGrid.propTypes = {
  data: PropTypes.object.isRequired,
}

export default CategoryGrid
