import React from "react"
import { graphql } from "gatsby"
import PropTypes from "prop-types"

import Image from "./Image"
import Heading from "./Heading"
import Link from "./LinkTM"

import styles from "./twoimages.module.scss"
import linkStyles from "./link.module.scss"
import settings from "./settings.module.scss"

import { toTitleCase } from "../helpers"


const TwoImages = ({ data }) => {
  const {
    images,
    videos,
    subtitle,
    link,
    fullWidth,
  } = data

  return (
    <section className={styles[`twoImages${(fullWidth) ? "FullWidth" : ""}`]}>
      <div
        className={styles.imagesContainer}
      >
        {images && images.map((image, index) =>
          <ImageContainer
            key={index}
            image={image}
            componentName={data.componentName}
          />
        )}
        {videos && videos.map((video, index) =>
          // eslint-disable-next-line jsx-a11y/media-has-caption
          <>
            {video.mobileVideo &&
            <video autoPlay loop muted playsInline className={styles.videoMobile}>
              <source
                src={video.mobileVideo.file.url}
                type="video/mp4" />
            </video>
            }
            <video autoPlay loop muted playsInline className={styles.video}>
              <source
                src={video.desktopVideo.file.url}
                type="video/mp4" />
            </video>
          </>
        )}
      </div>
      {subtitle &&
        <p
          className={styles.subtitle}
        >
          {subtitle.text}
        </p>
      }
      {link &&
        <Link
          url={`${link.url}${(link.url.includes("?")) ? "&" : "?"}origin=${data.componentName.toLowerCase().replace(/\s/g, "-")}`}
          text={link.text}
          className={linkStyles.linkAccent}
        />
      }
    </section>
  )
}

const ImageContainer = ({ image, componentName }) => {

  const textAlign = toTitleCase(image.textAlignment || "left")

  return(
    <div
      className={`${styles.imageContainer} ${settings[`textAlign${textAlign}`]}`}
    >
      {image.desktopImage &&
        <Image
          image={image}
          altText={image.altText}
          className={styles.image}
        />
      }
      {image.subtitle &&
        <Heading
          tag="h4"
          level="4"
          className={styles.heading}
        >
          {image.subtitle}
        </Heading>
      }
      {image.text &&
        <p
          className={styles.text}
        >
          {image.text}
        </p>
      }
      {image.url &&
        <Link
          url={`${image.url}${(image.url.includes("?")) ? "&" : "?"}origin=${componentName.toLowerCase().replace(/\s/g, "-")}`}
          className={linkStyles.linkFull}
        />
      }
    </div>
  )
}

TwoImages.propTypes = {
  data: PropTypes.object.isRequired,
}

export default TwoImages

export const query = graphql`
  fragment TwoImagesFragment on ContentfulTwoImages {
    componentName
    slug
    images {
      desktopImage {
        fluid(maxWidth: 850, quality: 90) {
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
      }
      altText
      subtitle
      text
      url
      textAlignment
    }
    videos {
        ...VideoFragment
    }
    fullWidth
    internal {
      type
    }
    id
  }
`

// export const query = graphql`
//   fragment TwoImagesFragment on ContentfulTwoImages {
//     componentName
//     slug
//     images {
//       desktopImage {
//         fluid(maxWidth: 850, quality: 90) {
//           ...GatsbyContentfulFluid_withWebp_noBase64
//         }
//       }
//       altText
//       subtitle
//       text
//       url
//       textAlignment
//     }
//     subtitle
//     link {
//       ...ButtonFragment
//     }
//     fullWidth
//     internal {
//       type
//     }
//     id
//   }
// `
